<template>
	<section id="main" class="h-screen w-full max-w-1024px m-x-auto overflow-y-auto relative" @scroll="domScroll">
		<nav class="h-80px text-14px text-white p-l-71px p-r-12px flex items-center flex-wrap">
			<img :src="image10Png" class="w-113px m-r-auto" />
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'home')">Home</div>
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'communication')">Communication</div>
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'community')">Community </div>
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'globalPayment')">Global Payment</div>
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'UMeke')">Why UMeke?</div>
			<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'monetization')">Monetization</div>
		</nav>

		<article id="home" class="h-658px bg1 bg-center bg-no-repeat relative bg-cover">
			<div class="flex justify-between h-full">
				<div class=" flex-1 h-full p-l-148px">
					<div class="h-190px" />
					<div class="text-40px font-bold text-white leading-tight">
						Bringing more value<br />
						by connecting with<br />
						people globally
					</div>
				</div>
				<div class="flex-1 h-full p-l-20px">
					<div class="text-20px font-400 text-white/74 leading-tight">
						<div class="p-t-95px" />
						Not only <span class="font-bold text-white">SocialFi</span><br />
						<div class="p-y-10 ">
							A solution for influencing and<br />
							overcoming the <span class="font-bold text-white">Web3</span> era.<br />
						</div>
						<div class="p-y-10">
							Assisting and serving individuals,<br />
							corporations, and organizations.<br />
						</div>
						Over 2 million users globally, spanning<br />
						across more than 50,000 communities.<br />
					</div>
					<div class="flex justify-evenly m-t-27 w-356px">
						<img :src="image5Png" class="h-44px cursor-pointer" @click="emits('download', 'android')" />
						<img :src="image4Png" class="h-44px cursor-pointer" @click="emits('download', 'ios')" />
					</div>
				</div>
			</div>
		</article>
		<div class="h-30px" />
		<article id="communication" class="h-674px bg2 bg-center bg-no-repeat bg-cover relative ">
			<div class="font-bold stroke p-l-106px p-t-73px">
				<!-- <span class="text-35px">Secure Communication that Functions</span> -->
				<img :src="umeke4Png" class="h-94px " />
			</div>
			<!-- <div class="font-bold stroke p-l-191px">
				<span class="text-35px"> Seamlessly Across All Countries Worldwide</span>
			</div> -->
			<div class="absolute h-full w-474px left-535px top-0">
				<div class="h-212px" />
				<div class="m-l-58px">
					<span class="text-28px text-bg">Safer</span>
					<div class="text-white text-14 font-300">
						We implement rigorous encryption protocols<br />
						to safeguard your privacy extensively, and<br />
						our platform does not retain any data related<br />
						to your usage.
					</div>
				</div>
				<div class="m-t-40px m-l-82px">
					<span class="text-28px text-bg">Smoother</span>
					<div class="text-white text-14 font-300">
						Our system is compatible with all countries<br />
						and regions globally, providing seamless<br />
						calling experiences (including in regions like<br />
						Mainland China and the Middle East).
					</div>
				</div>

				<div class="m-t-40px">
					<span class="text-28px text-bg">Faster</span>
					<div class="text-white text-14 font-300">
						Our instant messaging technology enables you<br />
						to efficiently communicate with friends via text,<br />
						voice, or video, ensuring swift message<br />
						delivery and the convenience of face-to-face<br />
						interactions.
					</div>
				</div>
			</div>
		</article>
		<article id="community" class="h-779px bg3 bg-center bg-no-repeat bg-cover relative">
			<div class="h-150px" />
			<div class="font-bold inline-block stroke p-l-64px">
				<!-- <span class="text-35px">Establish an Online Community</span> -->
				<img :src="umeke3Png" class="h-94px " />
			</div>
			<!-- <div>
				<div class="font-bold inline-block stroke p-l-129px">
					<span class="text-35px"> and Build Your Significant Influence</span>
				</div>
			</div> -->
			<div class="m-l-73px m-t-107px flex">
				<div>
					<span class="text-28px text-bg">Building a Community</span>
					<div class="text-14px text-white/74 m-t-7px m-b-20px">
						Establish a unique digital community and invite like-minded<br />
						friends to join. Your message will reach a wider audience, and<br />
						your impact will be greatly magnified. This is where your<br />
						exciting journey begins.
					</div>
					<span class="text-28px text-bg">Numerous Interactions</span>
					<div class="m-b-15px text-white/74">
							Establish a versatile online community platform where you can<br />
							tailor attributes, regulations, and engagements. You can craft<br />
							tasks, distribute rewards, spark discussions, and lead<br />
							conversations to foster community engagement. With diverse<br />
							interactions, you can bolster community vitality and attract new<br />
							members, amplifying your community's visibility and reach.
						</div>

						<span class="font-bold text-white">
							UMeke exclusive IP/EP points
						</span>
						<div class="text-white/74">
							Enhance the community's cohesion and imbue it with positive<br />
							momentum for growth! <br /><br />
							Accumulate IP/EP: Check-in, complete tasks, refer friends,<br />
							secure positions<br />
							Utilize IP/EP: For lottery, shopping, exchanges, and cashing out.
						</div>
				</div>



				<div class="w-91px" />
				<div class="p-t-20px">
					<span class="text-28px text-bg">Close Contact</span>
					<div class="text-14px m-t-7px m-b-51px">
						<span class="text-white/74">
							The high-quality voice and video in the community<br />
							provide an immersive experience, making you and<br />
							your friends feel like you're all together in the same<br />
							space. Whether it's a sharing session, a discussion,<br />
							or organizing other online activities, the experience is<br />
							seamless and engaging.
						</span>
					</div>

					<span class="text-28px text-bg">Efficient Monetization</span>
					<div class="text-14px m-t-7px">
						<span class="text-white/74">
							Building influence within the community lays the<br />
							groundwork for monetization. With UMeke, you can<br />
							efficiently tap into monetization path such as<br />
							community live broadcasts, sharing content, business<br />
							connections, and more.
						</span>
					</div>
				</div>
			</div>

		</article>
		<article id="globalPayment" class="h-768px bg4 bg-center bg-no-repeat bg-cover relative ">
			<div class="font-bold inline-block stroke absolute left-117px top-164px">
				<!-- <span class="text-35px">Experience Seamless, All-in-One </span> -->
				<img :src="umeke2Png" class="h-97" />
			</div>
			<!-- <div class="font-bold inline-block stroke absolute left-222px top-215px">
				<span class="text-35px">Financial Transactions Across Borders</span>
			</div> -->


			<div class="m-l-463px m-r-95px text-white p-t-354px grid grid-cols-[174px_1fr] gap-x-25px">
				<div class="text-28px text-right">Safety</div>
				<div class="text-14px text-white/74">Personal information is rigorously<br />
					safeguarded, and funds are securely<br />
					held in DBS Bank.</div>
				<div class="text-28px p-t-43px">Convenience</div>
				<div class="text-14px text-white/74">
					Incorporate numerous payment<br />
					channels to offer comprehensive,<br />
					borderless fund transfer services in<br />
					one place.
				</div>
				<div class="text-28px text-right leading-none p-t-22px">
					Adherence to<br />Regulations
				</div>
				<div class="text-14px text-white/74 p-t-22px">
					Adhere strictly to diverse systems and<br />
					possess licensing and oversight from<br />
					the Monetary Authority of Singapore<br />
					(MAS), alongside a QR code for easy<br />
					verification of qualifications.

				</div>
			</div>
		</article>
		<article id="UMeke" class="h-791px bg5 bg-center bg-no-repeat bg-cover relative">
			<div class="absolute left-90px top-208px text-bg1 text-35px">
				<img :src="umeke1Png" class="h-97" />
				<!-- UMeke Bringing More Value by -->
			</div>
			<!-- <div class="absolute left-166px top-250px text-bg1 text-35px">
				Connecting with People Globally
			</div> -->

			<div class="absolute left-56 w-912px top-388px text-white flex items-baseline">
				<div class="w-435px bg-black/60 b-rd-10px p-x-32px b-1 b-solid b-[#9A6446]">
					<div class="h-63 text-18px flex justify-center items-center relative"
						border="b-1 b-solid b-current">
						<span>Why Choose UMeke for Communication?</span>
					</div>
					<div class="text-15px p-y-17px leading-tight text-white/74">
						<div class="text-center m-b-10px">Efficiently engage with customers worldwide.</div>
						<div class="text-center">Enhanced voice and video calls for a more </div>
						<div class="text-center">immersive communication experience,  </div>
						<div class="text-center m-b-10px">such as face-to-face interactions.</div>
						<div class="text-center">Utilize instant messaging to  </div>
						<div class="text-center m-b-10px">achieve business objectives.</div>
						<div class="text-center">Forge enduring customer connections.</div>

					</div>
				</div>
				<div class="w-42px" />
				<div class="w-435px bg-black/60 b-rd-10px p-x-32px b-1 b-solid b-[#9A6446]">
					<div class="h-63 text-20px flex justify-center items-center relative"
						border="b-1 b-solid b-current">
						<span>Why Start a Community at UMeke?</span>
					</div>
					<div class="text-15px p-y-17px leading-tight text-white/74">
						<div class="text-center m-b-10px">No barriers, easy to use, suitable for beginners.</div>

						<div class="text-center">Affordable, no advertising expenses, </div>
						<div class="text-center m-b-10px">and no requirement to purchase traffic.</div>

						<div class="text-center">Easily connect with like-minded individuals </div>
						<div class="text-center m-b-10px">who share your interests.</div>

						<div class="text-center">Precisely align with business opportunities </div>
						<div class="text-center  m-b-10px">tailored to your preferences.</div>

						<div class="text-center">Swiftly establish personal IP and leverage it </div>
						<div class="text-center">for monetizing influence.</div>
					</div>
				</div>
			</div>
		</article>
		<article id="monetization" class="h-810px bg6 bg-center bg-no-repeat bg-cover relative">
			<div class="p-t-106px p-l-92px flex text-white/74">
				<div class="w-425px">
					<div class="text-bg text-28px font-bold leading-tight">Monetization Strategy for</div>
					<div class="text-bg text-28px font-bold leading-tight">Individual User Influence</div>
					<div class="text-14px flex justify-between m-y-15px">
						<div class="bg-black/6 b-rd-4px p-l-13px p-r-10px w-182px p-y-8px " border="1 solid #FFA01E">
							Designing, Developing, and Delivering Courses.
						</div>
						<div class="bg-black/6 b-rd-4px p-l-13px w-99px p-r-10px p-y-8px" border="1 solid #FFA01E">
							Live Video Streaming
						</div>
						<div class="bg-black/6 b-rd-4px p-l-13px w-99px p-r-10px p-y-8px" border="1 solid #FFA01E">
							Connecting Businesses
						</div>
					</div>
					<div class="text-14px flex">
						<div class="bg-black/6 b-rd-4px w-123px p-l-13px p-r-10px p-y-8px m-r-20px" border="1 solid #FFA01E">
							Web2 Project Representative
						</div>
						<div class="bg-black/6 b-rd-4px w-121px p-l-13px p-r-10px p-y-8px" border="1 solid #FFA01E">
							Web3 Project Management
						</div>
					</div>
					<div class="text-14px w-full m-t-18px">
						When users join UMeke, they can join preferred communities,<br />
						engage openly to boost influence, and create exclusive digital<br />
						communities with personalized features. They can also<br />
						manage communities, host live streaming, and use interactive<br />
						tools to grow a popular community and establish an influential<br />
						personal brand.<br /><br />
						With your personal IP, you can become a KOL in the Web3<br />
						domain and monetize through live broadcasts and lectures.<br />
						You can also connect with major brands as a provider,<br />
						opening opportunities for agents and dealers to excel and<br />
						achieve financial independence.
					</div>
				</div>
				<div class="w-85px" />
				<div class="w-425px">
					<div class="h-48px" />
					<div class="text-bg text-28px leading-tight font-bold">Monetization Strategy for </div>
					<div class="text-bg text-28px leading-tight font-bold">The Project Team</div>
					<div class="text-14px flex m-y-20px">
						<div class="bg-black/6 b-rd-4px p-l-13px p-r-10px p-y-8px m-r-13px" border="1 solid #FFA01E">
							Product Showcasing
						</div>
						<div class="bg-black/6 b-rd-4px p-l-13px p-r-10px p-y-8px" border="1 solid #FFA01E">
							Information Dissemination
						</div>
					</div>
					<div class="text-14px flex">
						<div class="bg-black/6 b-rd-4px p-l-13px p-r-10px p-y-8px m-r-13px" border="1 solid #FFA01E">
							Sales Growth
						</div>
						<div class="bg-black/6 b-rd-4px p-l-13px p-r-10px p-y-8px" border="1 solid #FFA01E">
							Maintaining User Engagement
						</div>
					</div>
					<div class="text-14px w-full m-t-25px">
						Project participants can create and manage their brand<br />
						communities on UMeke, boosting global product visibility<br />
						through content publishing, live streams, and showcases.<br />
						By using interactive methods like tasks, ads, and rewards,<br />
						they can increase user engagement. Collaborating with<br />
						KOLs and fan experts can help them expand sales channels,<br />
						generate private domain traffic, and globalize their projects.
					</div>
				</div>
			</div>
			<div class="absolute bottom-29px text-white w-full text-center">
					Copyright©2024 UMeke 版权所有 | UMeke All Rights Reserved.
				</div>
		</article>

		<img v-if="isVisible" :src="BackToTop" :style="{right: props.right}" class="fixed z-1 bottom-40% w-22px cursor-pointer" @click="emits('scrollInto', 'main')"/>
	</section>

</template>

<script setup>
import umeke1Png from "@/assets/images/en/umeke-1.png";
import umeke2Png from "@/assets/images/en/umeke-2.png";
import umeke3Png from "@/assets/images/en/umeke-3.png";
import umeke4Png from "@/assets/images/en/umeke-4.png";
import image4Png from "@/assets/images/en/Group-2013@2x.png";
import image5Png from "@/assets/images/en/Group-2014@2x.png";
import image10Png from "@/assets/images/en/Logo@2x.png";
import BackToTop from "@/assets/images/zh/BackToTopIcon@2x.png";
import { onBeforeUnmount, onMounted, ref } from "vue";

const isVisible = ref(false)
const emits = defineEmits(['scrollInto', 'download'])

const props = defineProps({
	right: {
		type: String,
		required: true
	}
})

onMounted(() => {
	const overflowDom = document.querySelector('.overflow-y-auto')
	if(overflowDom) {
		console.log("🚀 ~ onMounted ~ overflowDom:", overflowDom)
		overflowDom.addEventListener('scroll', domScroll)
	}
})

onBeforeUnmount(() => {
	const overflowDom = document.querySelector('.overflow-y-auto')
	if(overflowDom) {
		overflowDom.removeEventListener('scroll', domScroll)
	}
})

function domScroll(evt) {
	if(evt.target.scrollTop > 1000) {
		isVisible.value = true
	} else {
		isVisible.value = false
	}
}

</script>

<style lang="scss" scoped>
.bg1 {
	background-image: url("@/assets/images/en/Group-2012@2x.png");
}

.bg2 {
	background-image: url("@/assets/images/en/Group-2015@2x.png");
}

.bg3 {
	background-image: url("@/assets/images/en/BG@2x.png");
}

.bg4 {
	background-image: url("@/assets/images/en/Group-2016@2x.png");
}

.bg5 {
	background-image: url("@/assets/images/en/Group-2017@2x.png");
}

.bg6 {
	background-image: url("@/assets/images/en/Group-2018@2x.png");
}

.stroke {
	-webkit-text-stroke: 2px white;
	color: transparent;
}

.text-bg {
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	background-image: linear-gradient(108.82038586581295deg, #FF1B00 0%, #FF4B00 22%, #FF6E00 60%, #FF8300 70%, #FF8B00 76%, #FFC700 100%);
}

.text-bg1 {
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	background-image: linear-gradient(108.82038586581295deg, #FF1B00 0%, #FF4B00 22%, #FF6E00 60%, #FF8300 70%, #FF8B00 76%, #FFC700 100%);
}
</style>
