<template>
	<el-config-provider :size="config.size" :zIndex="config.zIndex" :button="config.button" :locale="zhCn">
		<zhCnPage v-if="lang == 'zh-CN'" :right="right" @scrollInto="scrollIntoView" @download="handleDownload" />
		<enUsPage v-else :right="right" @scrollInto="scrollIntoView" @download="handleDownload" />
	</el-config-provider>
</template>

<script setup>
import { onBeforeMount, shallowRef, ref, onMounted, onBeforeUnmount } from "vue"
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import zhCnPage from "./components/zhCn.vue"
import enUsPage from "./components/enUs.vue"

const right = ref('0px')
const lang = ref('zh-CN')

const config = shallowRef({
	size: "default",
	zIndex: 2000,
	button: {
		autoInsertSpace: false
	}
})

onBeforeMount(() => {
	getLanguage()
})

onMounted(() => {
	getWindowInfo()
	window.addEventListener('resize', getWindowInfo);
})

onBeforeUnmount(() => {
	window.removeEventListener('resize', getWindowInfo);
})

let timer = null
const getWindowInfo = () => {
	if(timer) clearInterval(timer)
	timer = setTimeout(() => {
		const clientWidth = document.body.clientWidth
		const width = (clientWidth - 1024) / 2
		right.value = `${width + 30}px`
	}, 800)
};

function scrollIntoView(id){
		try {
			const dom = document.querySelector('#'+id)
			if(id == 'main') {
				dom.scrollTop = 0
			} else {
				dom.scrollIntoView({ behavior: 'smooth' });
			}

		} catch (error) {
			console.error(error.message)
		}

}

function getLanguage(){
	if(localStorage.key('language')){
		const language = localStorage.getItem('language')
		if(language == 'zh-CN') {
			lang.value = language
		} else {
			lang.value = 'en-US'
		}
	} else {
		lang.value = navigator.language
	}
}


function handleDownload(type){
	if(type == 'ios') {
		window.open('https://testflight.apple.com/join/BKEyOVXx');
	} else {
		window.open('https://profile.utalk.vip/apps/Umeke_v1.3.0_03290559.apk');
	}
}

</script>

<style lang="scss">
@import '@/style/style.scss';
#app {
	background-color: #0a0a0a;
}
</style>

