<template>
<section id="main" class="h-screen w-full max-w-1024px m-x-auto relative overflow-y-auto">
			<nav class="h-80px text-14px text-white p-l-71px p-r-12px flex items-center flex-wrap">
				<img :src="image10Png" class="w-113px m-r-auto" />
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'home')">主页</div>
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'communication')">沟通</div>
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'community')">社区 </div>
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'globalPayment')">全球支付</div>
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'UMeke')">为什么选择UMeke?</div>
				<div class="cursor-pointer p-r-26px" @click="emits('scrollInto', 'monetization')">变现方案</div>
			</nav>
			<article id="home" class="h-658px bg1 bg-center bg-no-repeat text-white relative bg-cover">
				<div class="flex justify-between h-full">
					<div class=" flex-1 h-full p-l-125px">
						<div class="h-220px" />
						<span class="text-47px font-bold">让社交创造更大价值</span>
					</div>
					<div class="flex-1 h-full p-l-20px">
						<div class="text-20px text-white/74 font-400">
							<div class="p-t-205px " />
							不只是<span class="font-bold text-white">SocialFi</span><br />
							超越<span class="font-bold text-white">Web3</span>时代的影响力变现解决方案<br />
							服务于个人、企业、组织<br />
							全球超越200万+用户 ，500+社区
						</div>
						<div class="grid grid-cols-2 gap-x-25px m-t-37 w-306px">
							<img :src="image5Png" class="h-44px cursor-pointer" @click="emits('download', 'android')" />
							<img :src="image4Png" class="h-44px cursor-pointer" @click="emits('download', 'ios')" />
						</div>
					</div>
				</div>
			</article>
			<article id="communication" class="h-674px bg2 bg-center bg-no-repeat bg-cover relative p-t-30">
				<div class="font-bold inline-block stroke m-l-281px">
					<span class="text-50px">全球任何国家都能</span>
				</div>
				<div class="font-bold inline-block stroke m-l-406px">
					<span class="text-50px">流畅使用的安全通讯</span>
				</div>
				<div class="absolute h-full w-474px left-535px top-0">
					<div class="h-212px" />
					<div class="m-l-58px">
						<span class="text-40px text-bg">更安全</span>
						<div class="text-white/74 text-16 font-300">
							我们采取严格的加密措施，确保您的隐私得<br />
							到最大程度的保护，平台不会保留任何与您<br />
							的使用数据相关的信息。
						</div>
					</div>
					<div class="m-t-40px m-l-82px">
						<span class="text-40px text-bg">更流畅</span>
						<div class="text-white/74 text-16 font-300">
							我们的系统可以应用于全球任何国家和地区，<br />
							且在任意国家和地区（包括中国大陆和中东<br />
							地区）都能带来流畅的沟通体验。
						</div>
					</div>

					<div class="m-t-40px">
						<span class="text-40px text-bg">更快捷</span>
						<div class="text-white/74 text-16 font-300">
							当您和朋友通过文字，语音，视频交流时<br />
							我们的即时通讯技术能让您的消息快速传<br />
							达，享受面对面交流一般的便捷。
						</div>
					</div>
				</div>
			</article>
			<div class="h-77px" />
			<article id="community" class="h-787px bg3 bg-center bg-no-repeat bg-cover relative p-t-30">
				<div class="font-bold inline-block stroke m-l-72px p-t-80px">
					<span class="text-50px">创建数字社区</span>
				</div>
				<div>
					<div class="font-bold inline-block stroke m-l-165px p-t-8px">
						<span class="text-50px">打造你的超级影响力</span>
					</div>
				</div>
				<div class="m-l-116px m-t-80px">
					<span class="text-35px text-bg">更安全</span>
					<div class="text-14px text-white/74 m-t-12">
						创建自己的专属数字社区，邀请志同道合的好友加入，<br />
						你的声音将被更多人听到，你的影响力将能够无限放大，<br />
						在这里，您将开启一段奇妙的旅程。
					</div>
				</div>
				<div class="m-l-116px m-t-40px flex">
					<div>
						<span class="text-35px text-bg">多元互动</span>
						<div class="text-14px text-white/74 m-t-12">
							<span>
								在数字社区创建多频道社群，自定义属性，规则和活<br />
								动，您可以随时创建任务，发送红包，发起话题，组织<br />
								讨论等，通过开展多元互动带动社群的活跃度，让您的<br />
								社群被更多人发现。
							</span>

							<div class="m-y-15px">
								<span class="text-16px">UMeke 专属IP/EP积分</span>
								<div>让社区更具黏性，充满向上的力量！</div>
							</div>
							IP/EP赚取：打卡，做任务，邀请好友，锁仓<br />
							IP/EP消耗：抽奖，购物，兑换，提现
						</div>
					</div>
					<div class="p-l-88px">
						<span class="text-35px text-bg">紧密联系</span>
						<div class="text-14px text-white/74 m-t-12 m-b-47">
							<span>
								社群低延迟的语音和视频让您和朋友仿佛置身于同一间<br />
								屋子，无论是分享会，讨论会，还是组织其他线上活<br />
								动，都能有身临其境的感受。
							</span>
						</div>

						<span class="text-35px text-bg">高效变现</span>
						<div class="text-14px text-white/74 m-t-12">
							<span>
								在社区中创造影响力，就拥有了变现的基础。UMeke为<br />
								您打通了变现通道，您可以通过社区直播，社区分享，<br />
								业务配对等多种方式高效实现变现。
							</span>
						</div>
					</div>
				</div>
			</article>
			<div class="h-40px" />
			<article id="globalPayment" class="h-745px bg4 bg-center bg-no-repeat bg-cover relative p-t-30">
				<div class="font-bold inline-block text-black absolute left-224px top-208px">
					<!-- <span class="text-50px">享受无国界, 一站式的资金往来 </span> -->
					<img :src="umeke2Png" class="h-60px" />
				</div>
				<div class="m-l-571px m-r-95px p-t-360px grid grid-cols-[110px_1fr] gap-x-25px gap-y-54px">
					<div class="text-35px text-white">安全性</div>
					<div class="text-14px text-white/74">个人数据受到严格保护，资金安全存放于DBS星展银行。</div>
					<div class="text-35px text-white">便捷性</div>
					<div class="text-14px text-white/74">整合多种支付渠道，提供一站式，<br />无国界的资金往来服务。</div>
					<div class="text-35px text-white">合规性</div>
					<div class="text-14px text-white/74">严格遵守各项制度，受新加坡金融<br />监管局许可和监管（MAS）+ 二维<br />码（扫码查询资质）。</div>
				</div>
			</article>
			<article id="UMeke" class="h-791px bg5 bg-center bg-no-repeat bg-cover relative">
				<div class="absolute left-56px top-275px text-bg text-50px">
					<!-- UMeke 让社区创造更大价值 -->
					<img :src="umeke1Png" class="h-60px" />
				</div>
				<div class="absolute left-56 w-912px top-388px text-white/74 flex items-baseline">
					<div class="w-435px bg-black/60 b-rd-10px p-x-32px b-1 b-solid b-[#9A6446]">
						<div class="h-63 text-20px flex justify-center items-center relative" border="b-1 b-solid b-current">
							<span>为什么选择UMeke做沟通？</span>
							<img :src="image11Png" alt="" class="w-31px absolute top-15px right-0px">
						</div>
						<div class="text-15px p-y-17px leading-relaxed">
							<div class="text-center">与全球客户顺畅沟通</div>
							<div class="text-center">更流畅的语音，视频通话，如面对面沟通</div>
							<div class="text-center">即时通讯，推动业务成果</div>
							<div class="text-center">建立持久客户关系</div>
						</div>
					</div>
					<div class="w-42px" />
					<div class="w-435px bg-black/60 b-rd-10px p-x-32px b-1 b-solid b-[#9A6446]">
						<div class="h-63 text-20px flex justify-center items-center relative" border="b-1 b-solid b-current">
							<span>为什么在UMeke创建社区？</span>
							<img :src="image11Png" alt="" class="w-31px absolute top-15px right-0px">
						</div>
						<div class="text-15px p-y-17px leading-relaxed">
							<div class="text-center">为什么在UMeke创建社区？</div>
							<div class="text-center">零门槛，操作简单，新手友好</div>
							<div class="text-center">低成本，无需投放广告，无需购买流量</div>
							<div class="text-center">快速寻找与您有共同爱好的朋友</div>
							<div class="text-center">精准匹配适合您的商业机会</div>
							<div class="text-center">迅速打造个人IP，实现影响力变现</div>
						</div>
					</div>
				</div>
			</article>
			<article id="monetization" class="h-768px bg6 bg-center bg-no-repeat bg-cover relative">
				<div class="p-t-117px p-l-60px flex text-white/74">
					<div class="w-425px">
						<div class="text-bg text-30px font-bold">个人用户影响力变现方案：</div>
						<div class="text-14px flex justify-between m-y-20px">
							<div class="bg-black/6 b-rd-4px p-x-25px p-y-9px " border="1 solid #FFA01E">
								程设计，开发，培训
							</div>
							<div class="bg-black/6 b-rd-4px p-x-25px p-y-9px " border="1 solid #FFA01E">个人直播</div>
							<div class="bg-black/6 b-rd-4px p-x-25px p-y-9px" border="1 solid #FFA01E">业务配对</div>
						</div>
						<div class="text-14px flex">
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px m-r-13px" border="1 solid #FFA01E">Web2项目代理</div>
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px" border="1 solid #FFA01E">Web3项目运营</div>
						</div>
						<div class="text-14px w-full m-t-25px">
							个人用户加入UMeke，可以加入自己喜欢的社区，自由交流互动，提<br />
							升自己的影响力；也能够创建专属的数字社区，获得精准粉丝推送，<br />
							通过社区创建，个性装修，社区运营，视频直播，多元互动，打造超<br />
							高人气社区，形成自己的个人超级IP！ <br /><br />
							拥有个人IP，既能够在社群中借助个人影响力打造在WEB3领域打造<br />
							KOL身份，通过直播，授课等方式变现，还能够在社区与全球各大品<br />
							牌资源对接，做各大品牌的渠道商，代理商，经销商，更有机会在社<br />
							区更大的舞台上展示自己的才华和成功，实现财富自由！
						</div>
					</div>
					<div class="w-85px" />
					<div class="w-425px">
						<div class="h-113px" />
						<div class="text-bg text-30px font-bold">项目方影响力变现方案：</div>
						<div class="text-14px flex m-y-20px">
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px m-r-13px" border="1 solid #FFA01E">产品展示</div>
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px" border="1 solid #FFA01E">销售拓展</div>
						</div>
						<div class="text-14px flex">
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px m-r-13px" border="1 solid #FFA01E">信息推广</div>
							<div class="bg-black/6 b-rd-4px p-x-28px p-y-9px" border="1 solid #FFA01E">用户维护</div>
						</div>
						<div class="text-14px w-full m-t-25px">
							项目方加入UMeke，可以创建、经营自己专属的品牌社区。通<br />
							过发布资讯，视频直播，产品展示等方式推动产品展示全球<br />
							化；通过创建任务，发布广告，制定奖励等多元互动方式提升<br />
							用户及粉丝黏性；通过招募KOL，粉丝达人合作拓展自己的销<br />
							售渠道，打造企业全球私域流量，推动企业项目全球化进程！
						</div>
					</div>
				</div>
				<div class="absolute bottom-29px text-white w-full text-center">
					Copyright©2024 UMeke 版权所有 | UMeke All Rights Reserved.
				</div>
			</article>
			<img v-if="isVisible" :src="BackToTop" :style="{right: props.right}" class="fixed z-1 bottom-40% w-22px cursor-pointer" @click="emits('scrollInto', 'main')"/>
		</section>

</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import umeke1Png from "@/assets/images/zh/umeke-1.png";
import umeke2Png from "@/assets/images/zh/umeke-2.png";
import image4Png from "@/assets/images/zh/Group-2013@2x.png";
import image5Png from "@/assets/images/zh/Group-2014@2x.png";
import image10Png from "@/assets/images/zh/Logo@2x.png";
import image11Png from "@/assets/images/zh/click_11395499 2@2x.png";
import BackToTop from "@/assets/images/zh/BackToTopIcon@2x.png";

const emits = defineEmits(['scrollInto', 'download'])
const props = defineProps({
	right: {
		type: String,
		required: true
	}
})
const isVisible = ref(false)
onMounted(() => {
	const overflowDom = document.querySelector('.overflow-y-auto')
	if(overflowDom) {
		console.log("🚀 ~ onMounted ~ overflowDom:", overflowDom)
		overflowDom.addEventListener('scroll', domScroll)
	}
})

onBeforeUnmount(() => {
	const overflowDom = document.querySelector('.overflow-y-auto')
	if(overflowDom) {
		overflowDom.removeEventListener('scroll', domScroll)
	}
})

function domScroll(evt) {
	if(evt.target.scrollTop > 1000) {
		isVisible.value = true
	} else {
		isVisible.value = false
	}
}
</script>

<style lang="scss" scoped>
#app {
	background-color: #0a0a0a;
}
</style>
<style lang="scss" scoped>
.bg1{
	background-image: url("@/assets/images/zh/Group-2012@2x.png");
}
.bg2{
	background-image: url("@/assets/images/zh/Group-2015@2x.png");
}
.bg3{
	background-image: url("@/assets/images/zh/BG@2x.png");
}
.bg4{
	background-image: url("@/assets/images/zh/Group-2016@2x.png");
}
.bg5{
	background-image: url("@/assets/images/zh/Group-2017@2x.png");
}
.bg6{
	background-image: url("@/assets/images/zh/Group-2018@2x.png");
}
.stroke {
	background-color: #0a0a0a;
	-webkit-text-stroke: 2px white;
	color: transparent;

}

.text-bg{
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	background-image: linear-gradient(108.82038586581295deg, #FF1B00 0%, #FF4B00 22%, #FF6E00 60%, #FF8300 70%, #FF8B00 76%, #FFC700 100%);
}
</style>

