import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import { createApp } from 'vue'
import App from './App.vue'
import 'uno.css'
const app = createApp(App);
app.config.warnHandler = () => null
app.use(ElementPlus);
//挂载app
app.mount('#app');

